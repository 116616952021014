<template>
  <div class="group-games">
    <atomic-icon
      v-if="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.favorites.icon')"
      :id="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.favorites.icon')"
      filled
    />

    <h2 class="title">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.favorites.label') }}
    </h2>

    <nuxt-link class="btn-show-all" :to="localizePath('/favorites')">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
    </nuxt-link>

    <button-arrows
      v-if="showArrowButtons"
      :prev-disabled="prevDisabled"
      :next-disabled="nextDisabled"
      @click-action="clickAction"
    />

    <div
      ref="scrollContainer"
      class="items"
      :class="{ 'disabled-scroll-block': !favoriteGames.length }"
      @scroll="scrollHandler"
    >
      <template v-if="favoriteGames.length">
        <card-base v-for="(game, gameIndex) in favoriteGames" :key="gameIndex" :game-info="game" />
      </template>

      <template v-else>
        <div v-for="n in 18" :key="n" class="card-base">
          <div class="card-base__preview" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const { getContent, localizePath } = useProjectMethods();
  const gameStore = useGamesStore();
  const { favoriteGames } = storeToRefs(gameStore);

  const scrollContainer = ref();
  const prevDisabled = ref<boolean>(true);
  const nextDisabled = ref<boolean>(true);
  const showArrowButtons = ref<boolean>(true);

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value = scrollWidth < scrollLeft + offsetWidth + 20 && scrollWidth > scrollLeft + offsetWidth - 20;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  onMounted(() => {
    nextTick(() => {
      scrollHandler();
      showArrowButtons.value = !prevDisabled.value || !nextDisabled.value;
    });
  });

  watch(favoriteGames, () => {
    nextTick(() => {
      scrollHandler();
      showArrowButtons.value = !prevDisabled.value || !nextDisabled.value;
    });
  });
</script>

<style src="~/assets/styles/components/group/games.scss" lang="scss" />
